<template>
        <section class="component-container" id="services-outro">
        <div class="img-grid col-start-1 col-end-7">
            <div class="img-area img-area-1 col-start-1 col-end-5 row-start-1 row-end-2"></div>
            <div class="img-area img-area-2 col-start-1 col-end-5 ow-start-2 row-end-3"></div>
            <div class="img-area img-area-3 col-start-5 col-end-13 row-start-1 row-end-3"></div>
        </div>
        <div class="col-start-7 col-end-13">
            <h2 class="page-title">Our Approach</h2>
<!--             <p class="ac-text">
                Your brand is unique, and the experiences you create for your products and services should be unique too.
            </p>
            <p class="ac-text">
                That is why we place great emphasis in understanding your needs, clientele and goals before we start any project - so we can come up with efficient solutions that have been tailored specifically for your company.
            </p>
            <hr class="semi"> -->
            <p class="ac-text">
                We have a great amount of experience in the luxury way — but we place greater emphasis on innovation. Even if our ways of interacting with brands change, customers looking for luxury will always keep looking for luxury: That’s why we focus on creating meaningful interactions for a digital age.
            </p>
            <p class="ac-text">
                Do not hesitate to give us a call or to send us an e-mail to discuss how we can take your brand to a new age of luxury marketing.
            </p>
            <a class="contact-link" href="#contact">Contact Us > </a>
        </div>
    </section>
</template>

<script>

export default {
    name: "OurApproach"
}

</script>

<style scoped>

.contact-link{
    font-weight: 600;
    font-size: 1.7rem;
}

#services-outro, #services{
    align-items: stretch;
    margin-bottom: 6rem;
}

.img-grid{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 2rem;
}

.img-area{
    width: 100%;
    height: 100%;
    background-color: var(--color-background);
    background-size: cover;
    background-position: center center;
    opacity: 50%;
}

.img-area-1{
    background-image: url("../assets/services-1b.jpg");
    background-blend-mode: multiply;
}

.img-area-2{
    background-image: url("../assets/services-2.jpg");
    background-blend-mode: multiply;
}

.img-area-3{
    background-image: url("../assets/services-3.jpg");
    background-blend-mode: multiply;
}

@media screen and (max-width: 1050px){
    .img-grid{
        display: flex;
        flex-direction: column;
        gap: 3rem
    }
}



@media screen and (max-width: 850px){

    #services-outro{
        margin-bottom:0;
    }
    .img-grid{
        height: 100%;
        display:flex;
    }
}

@media screen and (max-width: 850px){

    .img-grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        width: 98.9vw;
        height: 40rem;
        gap:1rem;
    }

    .img-area-1{
        grid-column: 1 / 2;
        grid-row: 1 / 1;
    }
    .img-area-2{
        grid-column: 2 / 2;
        grid-row: 1 / 1;
    }

    .img-area-3{
        grid-column: 1 / 3;
        grid-row: 2 / 2 ;
    }

}
</style>