<template>
    <section id="references" class="component-container">
        <div class="text-container flex flex-col col-start-1 col-end-6">
            <h1 class="page-title"> Our References</h1>
            <p class="ac-text">
                As our company was founded in 2022, we are still working on adding all of our references to our webpage. Despite being a new company, our leadership has a decade of experience working with high-end companies.
            </p>
            <p class="ac-text">
                Our portfolio currently includes brands from the fashion industry, as well as some of the most prestigious watchmakers in the world.             </p>
            <p class="ac-text">
                You can see some of our most important clients below.
            </p>
        </div>
        <img class="col-start-7 col-end-13 w-full references" :src="require(`../assets/references-image.jpg`)">
    </section>
    <div id="reference-icons" class="component-container">

        <a class="md:col-start-3 md:col-end-5 pb-8  " href="https://www.breitling.com/us-en/">
            <img  :src="require(`../assets/references-breitling.png`)">
        </a>
        <a class="md:col-start-5 md:col-end-7  " href="https://www.hublot.com/en-us">
            <img  :src="require(`../assets/references-hublot.png`)">
        </a>
        <a  class="md:col-start-7 md:col-end-9 w-5/6 md:mx-auto "  href="https://www.damiani.com/en_ww/">
            <img :src="require(`../assets/references-damiani.png`)">
        </a>

        <a class="md:col-start-9 md:col-end-11  " href="https://www.tagheuer.com/tr/en/https://www.hublot.com/en-us">
            <img  :src="require(`../assets/references-tag-heuer.png`)">
        </a>

        <a  class="md:col-start-3 md:col-end-5 md:justify-self-center" href="https://www.quadran.com.tr/en">
            <img  :src="require(`../assets/references-quadran.png`)">
        </a>

        <a class="md:col-start-5 md:col-end-7" href="https://www.dogusgrubu.com.tr/en/dogus-hospitality-retail">
            <img  :src="require(`../assets/references-dogus.png`)">
        </a>

        <a class="md:col-start-7 md:col-end-9" href="https://kismetbymilka.com/">
            <img  :src="require(`../assets/references-kismet.webp`)">
        </a>

        <a class="md:col-start-9 md:col-end-11 pb-8" href="https://frederiqueconstant.com//">
            <img  :src="require(`../assets/references-fc.png`)">
        </a>

        <a class="md:col-start-3 md:col-end-5" href="https://www.bellross.com/">
            <img  :src="require(`../assets/references-br.png`)">
        </a>


    </div>

</template>

<script>

export default {
    name: "ReferencesPage"
}

</script>


<style scoped>
    img.references{
        mix-blend-mode: multiply;
        opacity: .5; 
    }

    #reference-icons{
        margin-top: 10rem;
        margin-bottom: 6rem;
    }

    @media screen and (max-width: 850px){
        #references{
            display:flex;
            flex-direction: column-reverse;
        }

        #reference-icons{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-content: flex-start;
            justify-items: flex-start;
            align-items:center;
            margin-top:unset;
        }


    }

    @media screen and (max-width: 850px){
        #reference-icons img{
            max-width: 15rem;
        }
    }




</style>