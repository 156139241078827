<template>
    <section id="services-top" class="component-container">
        <div class="col-start-1 col-end-7">
            <h1 class="page-title">Our Services</h1>
            <p class="ac-text">
                Luxury is the zenith of human experience. It might be an infrequent treat, but when it comes around, we demand for it to be absolutely perfect.
                        </p>
            <p class="ac-text">
                We know that the brands we represent can accept nothing less than perfection. Our experience in working with high-end brands and our dedication to creating memorable experiences is the defining vision of our services.</p>
        </div>
        <div class="col-start-7 col-end-13 services-top-img">
        </div>
    </section>
    <section class="full-bleed-green">
        <img class="diamond" :src="require(`../assets/diamond-2.png`)">
        <h2 class="mid-title">
            A New World. A New Customer. A New Concept of Luxury.
        </h2>
        <p class="ac-text text-white">
            At CD Medya, we aim to go beyond traditional definitions of luxury branding. 
        </p>
        <p class="ac-text text-white">
            As we continue to face world-changing events on a frequent basis, we always look to remain creative in our industry and provide meaningful solutions that will last. That is why we strive to create new experiences that will create strong bonds between your brand and the new digital consumer.
        </p>
        <p class="ac-text text-white pb-10">
            Here are some of our services to give you an idea of what we can do for you.
        </p>
    </section>
    <section id="service-list" class="component-container">
        <acIconView class="col-span-3" v-for="service in orderedServices" :key="service.title"
        :title="service.title"
        :text="service.text"
        :icon="service.iconName"
        />
    </section>

    <!-- <our-approach />-->
</template>





<script>

import acIconView from "../components/acIconView.vue"
//import ourApproach from "../components/ourApproach.vue"

export default {
    name: "ServicePage",
    components: {
        acIconView,
      //  ourApproach,
    },
    computed: {
        orderedServices(){
            return [...this.services].sort((a, b) => a.title < b.title ? -1 : a.title > b.title ? 1 : 0)
        }
    },
    data(){
        return {
            services: [
                {
                    title: "Events",
                    text: "We are here to support you in any and all events where you need a helping hand. Parties, ceremonies, dinners, fundraisers — you name it: We will be there to help.",
                    iconName: "events",
                    order: 1
                },
                {
                    title: "Store Openings",
                    text: "Whether you’re opening a new store or creating a pop-up experience, we can make sure you get off to a great start.",
                    iconName: "store",
                    order: 2
                },
                {
                    title: "Collection Launches",
                    text: "On the verge of something new? We might have some valuable experience when it comes to collection launches.",
                    iconName: "collection",
                    order: 3
                },
                {
                    title: "Brand Partnerships",
                    text: "People are excited when brands they love cooperate — even when they do not seem to be related. Interested in exciting and surprising partnerships?",
                    iconName: "puzzle",
                    order: 4
                },
                {
                    title: "Social Media Activation",
                    text: "There are no strong brands without strong social media presence in the 2020s. Let’s take your profiles above and beyond.",
                    iconName: "sm",
                    order: 5
                },
                {
                    title: "Influencer / Celebrity Collaboration",
                    text: "We can find the right individuals that will match up perfectly with your brand to increase your online presence.",
                    iconName: "influencer",
                    order: 6
                },
                {
                    title: "Experiential Marketing",
                    text: "Turn your marketing into much more by creating novel experiences for your current and potential clients.",
                    iconName: "experience",
                    order: 7
                },
                {
                    title: "Custom Made Strategies",
                    text: "Our greatest emphasis is to understand your needs perfectly, to create tailor-made solutions for your requirements.",
                    iconName: "custom",
                    order: 8
                },
            ]
        }
    }
}


</script>


<style scoped>

#services-top{
    margin-top: 6rem;
    margin-bottom: 10rem;
    align-items: flex-start;
}

.services-top-img{
        background-color: var(--color-background);
        background-image: url("../assets/services-top-4.jpg");
        background-position: bottom;
        background-repeat:no-repeat;
        background-size: 100%;
        height: 45rem;
        background-blend-mode: multiply;
        opacity: 85%;
    }

.full-bleed-green{
    width: 100vw;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-green);
    text-align:center;
    padding-top: 5rem;
    padding-bottom: 2rem;
    margin-bottom: 10rem;
    overflow-x: hidden;

}

h2.mid-title{
    color: var(--color-accent-lux);
    font-weight: 400;
    font-size: 3.6rem;
    line-height: 116%;
    margin-bottom: 4rem;
}

.full-bleed-green p.ac-text{
    max-width: 64rem;
}

.full-bleed-green img {
    width: 2.4rem;
    height: 2.4rem;
}



.text-white{
    color: #F3F0E8 !important;
}

#service-list{
    margin-bottom: 12rem;
}

hr.semi{
    background-color: var(--color-accent-lux);
    height: 1px;
    border: none;
    width: 65%;
    margin: 0 auto;
    color: var(--color-accent-lux);
    margin-bottom: 3rem;
}

#service-list{
    row-gap: 5.5rem;
    align-items: baseline;
    overflow-y: hidden;
}


@media screen and (max-width: 1050px){
    .img-grid{
        display: flex;
        flex-direction: column;
        gap: 3rem
    }
}

@media screen and (max-width: 930px){
    h2.mid-title{
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }

}


@media screen and (max-width: 850px){

    #services-top, #service-list{
        margin-bottom: 3rem;
        flex-direction: column-reverse;
    }

    .services-top-img{
        width: 100%;
    }



    .full-bleed-green{
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        margin-bottom: 2rem;
    }

    h2.mid-title{
        padding-left: unset;
        padding-right: unset;
        font-size: 3rem;
        margin-top: 1rem;
    }

}

</style>