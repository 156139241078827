<template>
    <div>
        <input @click="menuClicked" type="checkbox" class="responsive-menu__checkbox mobile-scale" id="navigation-toggle">
            <label for="navigation-toggle" id="navigation-button" class="navigation-button">
                <span id="navigation-icon" class="navigation__icon"> </span>
             </label>
                
        <nav id="re" :class='{blurs: isActive}' class="responsive-menu__navigation">
            <ul class="responsive-menu__navigation__list">
                <li  @click="closeNav" class="responsive-menu__navigation__item"><a href="#about-me">About Me</a></li>
                <li  @click="closeNav" class="responsive-menu__navigation__item "><a href="#references">References</a></li>
                <li  @click="closeNav" class="responsive-menu__navigation__item "><a href="#services">Services</a></li>
                <li  @click="closeNav" class="responsive-menu__navigation__item "><a href="#contact">Contact</a></li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    data() {
        return {
        isActive: false,
    }},
    methods: {
        closeNav: function() {
            document.getElementById("navigation-toggle").checked = false
            this.$emit("menuClosed")
        },
        menuClicked: function(){
            this.isActive = true
            this.$emit('menuOpen')
        }
    }
}
</script>

<style scoped>

.responsive-menu__checkbox{
    display: none;
}
.navigation-button{
    display: none;
    height: 7rem;
    width: 7rem;
    z-index: 2000;
    cursor: pointer;
}
.responsive-menu__checkbox:checked + .navigation-button {
    right: 3rem;
}
.navigation__icon {
    display: none;
    width: 3rem;
    height: 2px;
    background-color: var(--color-green);
    border-radius: 3px;
    transition: all .2s;
}
.responsive-menu__navigation{
    position: absolute;
    right: 0;
    top: -3.3rem;
    background-color: var(--color-background);
    height: 100%;
    padding: 0 2rem;
    overflow: hidden;
    box-shadow: 0 4px 12px 0px rgba(0,0,0,.25);
    visibility: hidden;
    width: 0vw;
    transition: width .3s, visibility .001s;
    z-index: 1999;
  
}
.responsive-menu__checkbox:checked ~ .responsive-menu__navigation {
    display: flex;
    width: 50vw;
    flex-direction: column;
    visibility: visible;
    transition: all .2s;
    justify-content: flex-start;
    align-items: flex-start;
}
.responsive-menu__navigation__list{
    list-style: none;
    margin-top: 20rem;
    color: var(--color-green);
}
.responsive-menu__navigation__item a{
    color: var(--color-green);
    text-decoration: none;
}
.responsive-menu__navigation__item{
    font-family: "Inter";
    font-size: 2.2rem;
    font-weight: 300;
    letter-spacing: -0.05em;
    margin-bottom: 1rem;
    text-decoration: none;
}
.navigation__icon::before {
    top: -.8rem;
    content: attr(data-before);
    position: absolute;
    left: 0;
    width: 3rem;
    height: 2px;
    background-color: var(--color-green);
    display: inline-block;
    border-radius: 3px;
    transition: all .2s;
}
.navigation__icon::after {
    content: attr(data-after);
    position: absolute;
    top: .8rem;
    left: 0;
    width: 3rem;
    height: 2px;
    background-color: var(--color-green);
    display: inline-block;
    transition: all .2s;
    border-radius: 3px;
}
.responsive-menu__checkbox:checked + .navigation-button .navigation__icon{
    background-color: black;
}
.responsive-menu__checkbox:checked + .navigation-button .navigation__icon::before{
    top: 0;
    z-index: 2000;
    transform: rotate(135deg);
    background-color: black;
}
.responsive-menu__checkbox:checked + .navigation-button .navigation__icon::after{
    top: 0;
    z-index: 2000;
    transform: rotate(-135deg);
    background-color: black;
}
.responsive-menu__language-switcher {
    display: flex;
    align-items: center;
}
.responsive-menu__language-switcher-separator{
      color: black;
      font-size: 1.5rem;
      font-weight: 600;
      margin: 0 .6rem;
}
.responsive-menu__language-switcher__option{
    font-size: 1.8rem;
    cursor: pointer;
}
.selected-language{
    font-weight: 600;
}
.animation-enter{
    -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.tracking-in-expand {
    -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  }

  @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }

@media only screen and (max-width: 900px) {
    .header-navigation {
        display: none;
    }
    .navigation-button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .navigation__icon{
        display: inline-block;
        position: relative;
    }
}
</style>
