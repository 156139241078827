<template>
    <div class="component-container contact-container">
        <h2 class="section-title">Contact Us</h2>
        <div class="form-area col-start-1 col-end-13 justify-self-center">
            <div class="ac-text w-5/6">
                Please don’t hesitate to contact us at your convenience to discuss how we can work together to grow your brand.
            </div>
            <form ref="form" @submit.prevent="sendEmail">
                <label for="name">Your Name
                    <input name="name" required v-model="name" type="text">
                </label>
                <label for="email">Your E-Mail Address
                    <input name="email" type="email" required v-model="email">
                </label>
                <label for="message"> Your Message
                    <textarea required name="message" id="message" v-model="message" placeholder="Enter Your Message Here" ></textarea>
                </label>
                <button type="submit"> Send </button>
            </form>
            <Transition>
                <div v-if="isFormSubmitted" class="success-notice">We have received your message! Thank you for contacting us. We will be responding as soon as possible!</div>
            </Transition>
        </div>

<!--         <div class="map-section col-start-7 col-end-13">
            <h4 class="mb-3">Contact Information</h4>
            <div class="map-area">
            <GMapMap
                :center="center"
                :zoom="18"
                map-type-id="terrain"
                style="width: 100%; height: 29rem;"
                :options="mapOptions"
            >
                    <GMapMarker                         
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        :clickable="true"
                        :draggable="false"
                        @click="center=m.position; openMarker(m.id)">
                        <GMapInfoWindow    
                            :closeclick="true"
                            @closeclick="openMarker(null)"
                            :opened="openedMarkerID === m.id"
                        >
                            <div>Cansın Denizer Consulting Agency <MyComponent/>
                            </div>
                        </GMapInfoWindow>
                    </GMapMarker>
            </GMapMap>
            </div>
           <p class="ac-text mt-3">
                Cevizli Mahallesi Nedret Sokak No 18 Maltepe / İstanbul
           </p>
           <div class="contact-info">
                <a href="tel:+90 536 836 80 10">+90 536 836 80 10</a>
                <a href="mailto:Cdenizer@cdmedya.com.tr">cdenizer@cdmedya.com.tr</a>
           </div>
        </div> -->
    </div>

</template>

<script>

import emailjs from "@emailjs/browser"

export default {
    name: "ContactPage",
    data(){
        return {
            openedMarkerID : "",
            mapOptions: {
                      zoomControl: true,
                      mapTypeControl: false,
                      scaleControl: true,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: true,
            },
            markers: [
                {
                id: 0,
                position: {
                lat: 40.919760, lng: 29.159480
                },
                }

            ],
            center: {lat: 40.919760, lng: 29.159480},
            isFormSubmitted: false,
            name: "",
            email: "",
            message: ""
        }
    },
    methods: {
    openMarker(id) {
       this.openedMarkerID = id
    },

    sendEmail() {
      emailjs.sendForm('service_tzev6w5', 'template_xp1cgmw', this.$refs.form, 'ndQ5E5boAeC9VTV5s')
        .then((result) => {
            this.isFormSubmitted = true
            console.log("Mail sent", result)
        }, (error) => {
            console.error(error)
        });
    }
  },
}

</script>


<style scoped>

    .contact-container{
        align-items: flex-start;
        margin-top: 5rem;
        margin-bottom: 6rem;
    }

    .form-area{
        padding-left: .4rem;
    }

    label, h4{
        color:#202532;
        font-size: 1.6rem;
        line-height: 150%;
        font-weight: 600;
    }

    a{
        font-size:1.8rem;
        display:block;
    }

    input[type="text"],
    input[type="email"]{
        background-color: #DEDEDE;
        border-radius: 4px;
        display:flex;
        padding: 1.2rem 0 1.2rem 1.6rem;
        width: 35.7rem;
        margin-bottom: 2rem;
        align-items: center;
    }

    textarea{
        background-color: #DEDEDE;
        border-radius: 4px;
        height: 14rem;
        width: 90%;
        padding: 1rem;
        margin-bottom:2rem;
    }

    @media screen and (max-width:600px){
        textarea{
            width: 100%;
        }
    }

    input[type="email"]:focus, input[type="text"]:focus, textarea:focus{
        outline: 1px solid var(--color-accent-lux);
    }


    .map-area{
        height: 29rem;
    }

    button{
        width: 140px;
        height: 48px;
        color: white;
        font-size: 1.6rem;

        background: #303C43;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
    }

    button:hover{
        background-color:black;
    }

    .success-notice{
        font-size: 1.6rem;
        text-align:left;
        margin-top:2rem;
        border: 2px solid var(--color-accent-lux);
        padding: 2rem;
        border-radius: 5px;;
        max-width: 90%;
    }

    .slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


</style>