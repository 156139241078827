<template>

    <div class="flex flex-col ac-icon-div">
            <img :src="require(`../assets/icons/${icon}.png`)" alt="icon" width="64px" class="ac-icon-div__image">
            <h6 class="ac-icon-div__title"> {{title}}</h6>
            <p class="ac-icon-div__text"> {{text}} </p>
    </div>

</template>

<script>
  export default{
      name: 'AcIconDiv',

      props: ['icon', 'title', 'text']
  }
</script>

<style scoped>


.ac-icon-div__image{
        width: 6.4rem;
        margin-bottom: 1.6rem;
    }

.ac-icon-div__title{
        font-size: 1.8rem;
        line-height: 116%;
        font-weight: 500;
        color: var(--color-accent-lux);
        margin-bottom: .6rem;
    }

.ac-icon-div__text{
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 116%;
}

@media screen and (max-width: 600px){

    .ac-icon-div__text{
        font-size: 1.8rem;
    }
    .ac-icon-div{

        align-items: center;
        padding: 0 3.2rem;
        text-align: center;
    }
    .ac-icon-div:not(:last-child){
        margin-bottom: 2rem;
        
    }


    .ac-icon-div__image{
        width: 6.4rem;
        margin: 0 auto;
        margin-bottom: 1.6rem;
    }
}

</style>