<template>
    <footer class="col-start-1 col-end-13">
        <div class="footer-content">
            <div class="copyright-text">
                @2023 Cansın Denizer
            </div>
            <div class="social-media flex">
                <a target="_blank" href="https://www.linkedin.com/in/cansın-denizer-83997977/" class="social-link mr-4">
                    <img width="30" height="30" :src="require(`../assets/icons/linkedin.png`)" />
                </a>
                <a target="_blank" href="https://www.instagram.com/cdconsultingagency" class="social-link">
                    <img width="30" height="30" :src="require(`../assets/icons/instagram.png`)" />
                </a>
            </div>
        </div>
     
    </footer>
</template>

<script>

export default{
    name: "ACFooter"
}



</script>

<style scoped>

footer{
    background-color: var(--color-green);
    height: 7rem;
    display: flex;
    align-items: center;
    max-width:100vw;
}

.footer-content{
    width: var(--site-width);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.copyright-text{
    font-size: 1.6rem;
    color: var(--color-background);
}
</style>