<template>
    <div class="component-container about-me-content">
        <img class="col-start-1 col-end-6 w-full  about-me" :src="require(`../assets/about-me-img.jpg`)">
        <div class="text-container flex flex-col col-start-6 col-end-13">
            <h1 class="about-me-title">I am Cansın Denizer, a professional with an experience of working with luxury brands.</h1>
            <p class="ac-text">
                I spent my entire career working with high-end brands, creating memorable services, experiences and strategies for brands offering luxurious products and services for their customers.            
            </p>

            <p class="ac-text">
                My journey began in close contact with the fashion industry, as I took part in store openings, events and marketing initiatives of world-famous brands like Armani, Gucci and Loro Piana. The early days of my career allowed me to have a perspective on different aspects of our business, as I worked in different fields of luxury, including sales, marketing and brand consulting. 
            </p>

            <p class="ac-text">
                I played part in the forming days of Zubizu, a digital loyalty program in Turkey. My six years with the company helped me gain a lot of experience working with a product that aimed to bring the luxury way to the new, digital consumer, and also put me in close contact with brands like Harvey Nichols, Vakko, Hugo Boss, and more.            </p>

            <p class="ac-text"> 
                Lastly, I worked both in brand management and PR parts of D-Saat which is the biggest watch and jewellery distributor in Turkey with its mono boutiques and multi-brand stores named Quadran.
            </p>                
            <p class="ac-text">
                In 2022, I decided to create my own agency to provide improved service to my clients and to bring a new, fresh look based on creating custom-made experiences and creative approaches for luxury industry. 
            </p>   

            <p class="ac-text">
                I hope that with my extensive experience and expertise in the world of luxury, we can create unique strategies for your company, and help you create long-lasting, meaningful relationships with your clients.            </p>   

            </div>
    </div>
</template>

<script>

export default {
    name: "AboutMePage",
    data() {
        return {

        }
    },
    components: {

    }
}
</script>


<style scoped>

.about-me-content{
    align-items: flex-start;
    margin-bottom: 9rem;

}

img.about-me{
    mix-blend-mode: multiply;
}
h1.about-me-title{
    font-weight: 300;
    font-size: 3.6rem;
    line-height: 116%;
    margin-bottom: 2.6rem;
}

@media screen and (max-width: 850px){
    .about-me-content{
        margin-top:2rem;
        margin-bottom: 0;
    }
    
}
</style>