import { createApp } from 'vue'
import App from './App.vue'
import "./styles/tw.css"
import "./styles/styles.css"
import { initializeApp } from "firebase/app";
import { routes } from "./routes.js"
import {createRouter, createWebHistory} from "vue-router"




const firebaseConfig = {
    apiKey: "AIzaSyBWjiGBzrUX2Ti9QSqTQl5XFEFAMWja264",
    authDomain: "cansin-denizer-web.firebaseapp.com",
    projectId: "cansin-denizer-web",
    storageBucket: "cansin-denizer-web.appspot.com",
    messagingSenderId: "331457399011",
    appId: "1:331457399011:web:2db932c565e1585853ddd6"
  };
  
initializeApp(firebaseConfig);

const router = createRouter({
    scrollBehavior() {
        return {top: 0}
    },
    history: createWebHistory(),
    routes
})

const app = createApp(App)
app.use(router)

app.mount('#app')
