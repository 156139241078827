<template>
   <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 220.07 138.87">
        <defs></defs>
        <g>
            <path class="cls-1"
                d="M37.56,122.87c1.1,0,1.9,.38,2.64,1.01,.09,.09,.1,.22,.01,.31l-.29,.29c-.09,.11-.17,.1-.28-.01-.57-.49-1.34-.82-2.08-.82-1.72,0-3.04,1.45-3.04,3.14s1.32,3.13,3.04,3.13c.95,0,1.51-.38,2.08-.82,.11-.09,.2-.08,.26-.03l.33,.29c.09,.07,.07,.22-.01,.29-.74,.72-1.68,1.06-2.65,1.06-2.18,0-3.94-1.73-3.94-3.91s1.76-3.94,3.94-3.94Z" />
            <path class="cls-1"
                d="M47.4,122.87c2.18,0,3.93,1.76,3.93,3.94s-1.74,3.91-3.93,3.91-3.91-1.73-3.91-3.91,1.73-3.94,3.91-3.94Zm0,7.09c1.73,0,3.16-1.42,3.16-3.15s-1.43-3.17-3.16-3.17-3.15,1.44-3.15,3.17,1.42,3.15,3.15,3.15Z" />
            <path class="cls-1"
                d="M55.19,123.07c0-.11,.1-.2,.21-.2h.27l4.92,6.13h.02v-5.81c0-.11,.09-.21,.21-.21h.38c.11,0,.21,.1,.21,.21v7.34c0,.11-.1,.2-.21,.2h-.2l-5.01-6.25h-.01v5.93c0,.11-.09,.21-.21,.21h-.38c-.11,0-.21-.1-.21-.21v-7.34Z" />
            <path class="cls-1"
                d="M65.32,129.47c.05-.07,.11-.14,.16-.21,.11-.14,.23-.23,.38-.1,.08,.07,.87,.83,1.84,.83,.88,0,1.46-.56,1.46-1.2,0-.75-.65-1.2-1.91-1.72-1.2-.52-1.92-1.01-1.92-2.26,0-.74,.59-1.94,2.32-1.94,1.07,0,1.86,.56,1.86,.56,.07,.03,.2,.16,.07,.37-.04,.07-.09,.14-.13,.21-.1,.15-.21,.2-.38,.1-.08-.04-.76-.5-1.43-.5-1.16,0-1.51,.74-1.51,1.2,0,.73,.56,1.16,1.47,1.54,1.47,.6,2.42,1.16,2.42,2.42,0,1.13-1.08,1.96-2.36,1.96s-2.16-.75-2.3-.88c-.09-.08-.2-.16-.07-.37Z" />
            <path class="cls-1"
                d="M73.71,123.19c0-.11,.1-.21,.21-.21h.41c.12,0,.21,.1,.21,.21v4.55c0,1.24,.77,2.22,2.05,2.22s2.07-.95,2.07-2.19v-4.57c0-.11,.09-.21,.21-.21h.41c.11,0,.21,.1,.21,.21v4.61c0,1.66-1.18,2.92-2.9,2.92s-2.88-1.27-2.88-2.92v-4.61Z" />
            <path class="cls-1"
                d="M83.81,123.19c0-.11,.09-.21,.21-.21h.42c.11,0,.21,.1,.21,.21v6.7h3.14c.12,0,.21,.1,.21,.21v.32c0,.11-.09,.21-.21,.21h-3.77c-.12,0-.21-.1-.21-.21v-7.22Z" />
            <path class="cls-1"
                d="M91.95,123.71h-1.9c-.12,0-.21-.1-.21-.21v-.32c0-.11,.09-.21,.21-.21h4.62c.12,0,.21,.1,.21,.21v.32c0,.11-.09,.21-.21,.21h-1.9v6.7c0,.11-.1,.21-.21,.21h-.41c-.11,0-.21-.1-.21-.21v-6.7Z" />
            <path class="cls-1"
                d="M98.26,123.19c0-.11,.1-.21,.21-.21h.44c.11,0,.21,.1,.21,.21v7.22c0,.11-.1,.21-.21,.21h-.44c-.11,0-.21-.1-.21-.21v-7.22Z" />
            <path class="cls-1"
                d="M103.52,123.07c0-.11,.1-.2,.21-.2h.27l4.92,6.13h.02v-5.81c0-.11,.09-.21,.21-.21h.38c.11,0,.21,.1,.21,.21v7.34c0,.11-.1,.2-.21,.2h-.2l-5.01-6.25h-.01v5.93c0,.11-.09,.21-.21,.21h-.38c-.11,0-.21-.1-.21-.21v-7.34Z" />
            <path class="cls-1"
                d="M117.53,122.87c1.1,0,1.9,.38,2.64,1.01,.09,.09,.1,.22,.01,.31-.1,.1-.22,.21-.31,.31-.1,.11-.16,.1-.28-.01-.56-.49-1.34-.85-2.07-.85-1.72,0-3.03,1.44-3.03,3.13s1.31,3.15,3.03,3.15c1.2,0,1.96-.46,1.96-.46v-1.53h-1.25c-.12,0-.21-.09-.21-.2v-.4c0-.12,.09-.21,.21-.21h1.89c.11,0,.2,.1,.2,.21v2.5c0,.05-.04,.14-.09,.18,0,0-1.11,.72-2.69,.72-2.18,0-3.94-1.73-3.94-3.91s1.76-3.94,3.94-3.94Z" />
            <path class="cls-1"
                d="M127.88,130.33l3.32-7.34c.03-.07,.09-.12,.19-.12h.11c.1,0,.15,.05,.19,.12l3.29,7.34c.07,.14-.02,.28-.19,.28h-.44c-.1,0-.16-.07-.19-.12l-.81-1.8h-3.87l-.8,1.8c-.02,.05-.09,.12-.19,.12h-.44c-.16,0-.25-.14-.19-.28Zm5.18-2.31c-.53-1.19-1.06-2.39-1.59-3.58h-.09l-1.59,3.58h3.27Z" />
            <path class="cls-1"
                d="M141.68,122.87c1.1,0,1.9,.38,2.64,1.01,.09,.09,.1,.22,.01,.31-.1,.1-.22,.21-.31,.31-.1,.11-.16,.1-.28-.01-.56-.49-1.34-.85-2.07-.85-1.72,0-3.03,1.44-3.03,3.13s1.31,3.15,3.03,3.15c1.2,0,1.96-.46,1.96-.46v-1.53h-1.25c-.12,0-.21-.09-.21-.2v-.4c0-.12,.09-.21,.21-.21h1.89c.11,0,.2,.1,.2,.21v2.5c0,.05-.04,.14-.09,.18,0,0-1.11,.72-2.69,.72-2.18,0-3.94-1.73-3.94-3.91s1.76-3.94,3.94-3.94Z" />
            <path class="cls-1"
                d="M148.47,123.19c0-.11,.09-.21,.21-.21h4.3c.12,0,.21,.1,.21,.21v.32c0,.11-.09,.21-.21,.21h-3.66v2.66h3.13c.11,0,.21,.1,.21,.21v.32c0,.12-.1,.21-.21,.21h-3.13v2.78h3.66c.12,0,.21,.1,.21,.21v.32c0,.11-.09,.21-.21,.21h-4.3c-.12,0-.21-.1-.21-.21v-7.22Z" />
            <path class="cls-1"
                d="M157.11,123.07c0-.11,.1-.2,.21-.2h.27l4.92,6.13h.02v-5.81c0-.11,.09-.21,.21-.21h.38c.11,0,.21,.1,.21,.21v7.34c0,.11-.1,.2-.21,.2h-.2l-5.01-6.25h-.01v5.93c0,.11-.09,.21-.21,.21h-.38c-.11,0-.21-.1-.21-.21v-7.34Z" />
            <path class="cls-1"
                d="M171.12,122.87c1.1,0,1.9,.38,2.64,1.01,.09,.09,.1,.22,.01,.31l-.29,.29c-.09,.11-.17,.1-.28-.01-.57-.49-1.34-.82-2.08-.82-1.72,0-3.04,1.45-3.04,3.14s1.32,3.13,3.04,3.13c.95,0,1.51-.38,2.08-.82,.11-.09,.2-.08,.26-.03l.33,.29c.09,.07,.07,.22-.01,.29-.74,.72-1.68,1.06-2.65,1.06-2.18,0-3.94-1.73-3.94-3.91s1.76-3.94,3.94-3.94Z" />
            <path class="cls-1"
                d="M179.17,126.94l-2.63-3.64c-.09-.14,0-.32,.17-.32h.51c.08,0,.14,.05,.17,.1l2.2,3.04,2.2-3.04s.1-.1,.17-.1h.51c.17,0,.26,.17,.17,.32l-2.66,3.63v3.48c0,.11-.1,.21-.21,.21h-.42c-.12,0-.21-.1-.21-.21v-3.47Z" />
        </g>
        <g>
            <path class="cls-1"
                d="M0,103.62c0-4.31,2.85-7.41,6.91-7.41,3.29,0,5.81,2.16,5.81,3.89,0,.69-.44,1.15-1.19,1.15-1.91,0-1.81-3.97-5.18-3.97-2.06,0-3.87,1.94-3.87,4.89,0,4.16,2.58,6.99,5.22,6.99,2.29,0,3.7-1.73,4.33-3.04,.29-.44,.98-.33,.9,.29-.35,2.6-2.33,4.97-5.87,4.97-3.75,0-7.06-3.79-7.06-7.76Z" />
            <path class="cls-1"
                d="M30.89,110.82v.06c0,.19-.17,.29-.44,.29h-3.7c-.27,0-.44-.1-.44-.29v-.06c0-.46,1.08-.56,.77-1.37l-.81-2.06h-6.29l-.77,2.06c-.31,.81,.77,.92,.77,1.37v.06c0,.19-.17,.29-.46,.29h-2.35c-.27,0-.44-.1-.44-.29v-.06c0-.46,.71-.48,1.06-1.37l4.37-11.22c.27-.69-.69-1.08-.69-1.48v-.06c0-.17,.13-.29,.35-.29h1.64c.56,0,.9,.23,1.12,.79l5.22,12.26c.37,.87,1.06,.92,1.06,1.37Zm-5.06-4.56l-2.79-7.12-2.64,7.12h5.43Z" />
            <path class="cls-1"
                d="M48.87,96.71v.06c0,.44-.87,.54-.87,1.35v12.34c0,.46-.25,.71-.67,.71h-.75c-.27,0-.48-.17-.77-.58l-8.43-11.45v10.26c0,.83,.92,.96,.92,1.41v.06c0,.19-.19,.29-.46,.29h-2.14c-.29,0-.46-.1-.46-.29v-.06c0-.46,.81-.58,.81-1.41v-11.26c0-.81-.81-.9-.81-1.35v-.08c0-.19,.17-.31,.46-.31h2.06c.4,0,.65,.17,.87,.5l8.03,11.18v-9.95c0-.81-.88-.92-.88-1.35v-.06c0-.19,.19-.31,.46-.31h2.16c.27,0,.46,.13,.46,.31Z" />
            <path class="cls-1"
                d="M53.8,107.68c0-.58,.35-1.06,1.02-1.04,1.89,.06,1.21,3.75,5.1,3.75,1.69,0,2.85-1,2.85-2.5,0-4.79-8.66-1.27-8.66-7.51,0-2.33,1.94-4.16,5.37-4.16s5.6,1.91,5.58,3.5c0,.64-.35,1.06-1.08,1.06-1.96,0-1.06-3.56-4.56-3.56-1.83,0-2.77,1.14-2.77,2.48,0,4.5,8.66,1.37,8.66,7.51,0,2.64-2.56,4.18-5.49,4.18-4.1,0-6.02-2.23-6.02-3.7Z" />
            <path class="cls-1"
                d="M70.39,110.82c0-.44,.77-.54,.77-1.35v-11.34c0-.81-.77-.92-.77-1.37v-.06c0-.19,.17-.29,.44-.29h3.25c.27,0,.44,.1,.44,.29v.06c0,.46-.83,.56-.83,1.37v11.34c0,.81,.83,.92,.83,1.35v.06c0,.19-.17,.29-.44,.29h-3.25c-.27,0-.44-.1-.44-.29v-.06Z" />
            <path class="cls-1"
                d="M93.66,96.71v.06c0,.44-.87,.54-.87,1.35v12.34c0,.46-.25,.71-.67,.71h-.75c-.27,0-.48-.17-.77-.58l-8.43-11.45v10.26c0,.83,.92,.96,.92,1.41v.06c0,.19-.19,.29-.46,.29h-2.14c-.29,0-.46-.1-.46-.29v-.06c0-.46,.81-.58,.81-1.41v-11.26c0-.81-.81-.9-.81-1.35v-.08c0-.19,.17-.31,.46-.31h2.06c.4,0,.65,.17,.87,.5l8.03,11.18v-9.95c0-.81-.88-.92-.88-1.35v-.06c0-.19,.19-.31,.46-.31h2.16c.27,0,.46,.13,.46,.31Z" />
            <path class="cls-1"
                d="M106.86,110.88v-.06c0-.46,.79-.56,.79-1.37v-11.32c0-.81-.79-.92-.79-1.37v-.06c0-.19,.19-.29,.46-.29h4.58c5.14,0,8.76,3.16,8.76,7.33,0,4.6-3.31,7.45-7.83,7.45h-5.51c-.27,0-.46-.1-.46-.29Zm5.83-.77c3.1,0,5.39-2.06,5.39-5.45,0-4.14-2.89-7.2-6.66-7.2h-.58c-.39,0-.71,.21-.71,.77v10.41c0,.94,.48,1.48,1.25,1.48h1.31Z" />
            <path class="cls-1"
                d="M125.71,110.88v-.06c0-.46,.79-.54,.79-1.39v-11.28c0-.85-.79-.96-.79-1.39v-.06c0-.19,.17-.29,.42-.29h9.05c.38,0,.52,.17,.58,.52l.5,2.25c.06,.31-.08,.48-.33,.48h-.1c-.83,0-1.42-2.21-2.62-2.21h-3.5c-.42,0-.71,.23-.71,.81v4.85h3.2c.9,0,.98-1.37,1.46-1.37h.13c.19,0,.31,.15,.31,.4v2.96c0,.23-.13,.4-.31,.4h-.13c-.48,0-.56-1.31-1.46-1.31h-3.2l-.02,4.87c0,.54,.27,1.1,.94,1.1h3.93c1.23,0,1.65-2.29,2.54-2.29h.15c.23,0,.35,.23,.31,.48l-.48,2.31c-.08,.35-.23,.54-.6,.54h-9.59c-.27,0-.46-.1-.46-.29Z" />
            <path class="cls-1"
                d="M155.29,96.71v.06c0,.44-.87,.54-.87,1.35v12.34c0,.46-.25,.71-.67,.71h-.75c-.27,0-.48-.17-.77-.58l-8.43-11.45v10.26c0,.83,.92,.96,.92,1.41v.06c0,.19-.19,.29-.46,.29h-2.14c-.29,0-.46-.1-.46-.29v-.06c0-.46,.81-.58,.81-1.41v-11.26c0-.81-.81-.9-.81-1.35v-.08c0-.19,.17-.31,.46-.31h2.06c.4,0,.65,.17,.87,.5l8.03,11.18v-9.95c0-.81-.88-.92-.88-1.35v-.06c0-.19,.19-.31,.46-.31h2.16c.27,0,.46,.13,.46,.31Z" />
            <path class="cls-1"
                d="M160.7,110.82c0-.44,.77-.54,.77-1.35v-11.34c0-.81-.77-.92-.77-1.37v-.06c0-.19,.17-.29,.44-.29h3.25c.27,0,.44,.1,.44,.29v.06c0,.46-.83,.56-.83,1.37v11.34c0,.81,.83,.92,.83,1.35v.06c0,.19-.17,.29-.44,.29h-3.25c-.27,0-.44-.1-.44-.29v-.06Zm.5-17.11c0-.85,.64-1.5,1.64-1.5s1.6,.64,1.6,1.5-.64,1.48-1.6,1.48-1.64-.62-1.64-1.48Z" />
            <path class="cls-1"
                d="M181.81,108.18l-.6,2.54c-.08,.25-.25,.46-.5,.46h-10.57c-.25,0-.46-.19-.46-.42v-.17c0-.23,.19-.46,.33-.67l8.33-12.49h-5.72c-1.02,0-1.44,2.29-2.33,2.29h-.1c-.31,0-.48-.19-.4-.58l.56-2.23c.08-.33,.21-.52,.6-.52h9.91c.23,0,.42,.19,.42,.42v.21c0,.15-.17,.46-.38,.77l-8.26,12.34h6.1c1.33,0,1.83-2.41,2.64-2.41h.06c.31,0,.46,.17,.37,.46Z" />
            <path class="cls-1"
                d="M186.45,110.88v-.06c0-.46,.79-.54,.79-1.39v-11.28c0-.85-.79-.96-.79-1.39v-.06c0-.19,.17-.29,.42-.29h9.05c.37,0,.52,.17,.58,.52l.5,2.25c.06,.31-.08,.48-.33,.48h-.1c-.83,0-1.42-2.21-2.62-2.21h-3.5c-.42,0-.71,.23-.71,.81v4.85h3.2c.9,0,.98-1.37,1.46-1.37h.13c.19,0,.31,.15,.31,.4v2.96c0,.23-.13,.4-.31,.4h-.13c-.48,0-.56-1.31-1.46-1.31h-3.2l-.02,4.87c0,.54,.27,1.1,.94,1.1h3.93c1.23,0,1.64-2.29,2.54-2.29h.15c.23,0,.35,.23,.31,.48l-.48,2.31c-.08,.35-.23,.54-.6,.54h-9.59c-.27,0-.46-.1-.46-.29Z" />
            <path class="cls-1"
                d="M215.11,110.82v.06c0,.19-.17,.29-.44,.29h-3.62c-.27,0-.44-.1-.44-.29v-.06c0-.46,.94-.52,.44-1.37l-2.64-3.95h-.19l-2.48,.04v3.91c0,.81,.85,.92,.85,1.37v.06c0,.19-.19,.29-.46,.29h-3.27c-.27,0-.44-.1-.44-.29v-.06c0-.46,.77-.56,.77-1.37v-11.32c0-.81-.77-.92-.77-1.37v-.06c0-.19,.17-.29,.44-.29h4.7c3.64,0,6.26,1.48,6.29,4.62,0,1.73-1.06,3.33-3.1,4.06l2.96,4.37c.6,.9,1.39,.92,1.39,1.37Zm-3.83-9.49c0-2.58-1.89-3.87-3.87-3.87h-1.04c-.42,0-.62,.33-.62,.83v4.98c0,.87,.35,1.19,1.02,1.19h1.17c1.96,0,3.35-1.08,3.35-3.12Z" />
        </g>
        <g>
            <polygon class="cls-1"
                points="131.14 74.47 86.8 74.47 86.8 6.88 131.14 6.88 131.14 7.93 87.85 7.93 87.85 73.42 131.14 73.42 131.14 74.47" />
            <polygon class="cls-1"
                points="121.44 58.59 95.92 58.59 95.92 22.94 121.44 22.94 121.44 23.99 96.97 23.99 96.97 57.54 121.44 57.54 121.44 58.59" />
            <polygon class="cls-1"
                points="121.97 50.05 120.92 50.05 120.92 32.01 106.08 32.01 106.08 50.05 105.03 50.05 105.03 30.96 121.97 30.96 121.97 50.05" />
            <polygon class="cls-1"
                points="130.98 66.52 96.44 66.52 96.44 65.47 129.93 65.47 129.93 15.94 96.44 15.94 96.44 14.89 130.98 14.89 130.98 66.52" />
        </g>
    </svg>
</template>