<template>
    <section class="homepage-texts component-container">
        <div class="flex flex-col col-start-1 col-end-6 items-start">
            <p class="upper-slogan mb-4">Redefining luxury</p>
            <h3 class="main-slogan">Custom-made, unique luxury experiences to create long-lasting bonds with your customers.</h3>
            <div class="separator"></div>
        </div>
        <div class="main-text col-start-7 col-end-13">
            <p class="ac-text">
                Keeping an eye for the future, we provide creative strategies for the luxury way in a world of constant change. 
                        </p>
            <p class="ac-text">
                Our strategy depends on your brand needs and providing tailor-made solutions that will create unforgettable experiences for your customers.            </p>
            <p class="ac-text">
                We believe that luxury is not just about the product or services. It's about the experience. Your brand is unique and we believe that we can help to elevate your products and services with interactions that will leave a lasting impression on your customers.             </p>
        </div>
    </section>
</template>

<script>

export default{
    name: "homePageHeader",
    data(){
        return {

        }
    }
}
</script>

<style scoped>
.homepage-texts{
    margin-bottom: 9rem;
    align-items: baseline;

}

.upper-slogan{
    font-size: 2.8rem;
    line-height: 116%;
    font-weight: 300;
}

.main-slogan{
    font-weight: 500;
    font-size: 4rem;
    line-height: 116%;
    margin-bottom: 5rem;
}
.separator{
    height: 1px;
    width: 100%;
    background-color: var(--color-accent-lux);

}

@media screen and (max-width: 600px){
    .homepage-texts{
        margin-bottom: 4.5rem;
    }

    .main-slogan{
        font-size: 3rem;
    }

    .upper-slogan{
        font-size: 2.2rem;
    }
}


</style>