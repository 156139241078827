import HomePage from "./pages/HomePage.vue"
/* import ReferencesPage from "./pages/ReferencesPage.vue"
import ServicesPage from "./pages/ServicesPage.vue"
import ContactPage from "./pages/ContactPage.vue"
import AboutMePage from "./pages/AboutMePage.vue" */


export const routes = [
    {path: "", name: "HomePage", components: {
        default: HomePage, 
    }},
]
/*     {path: "/about-me", name: 'About Me Page',
    components: {
       default: AboutMePage, 
   }},

    {path: "/services", name: 'ServicesPage',
     components: {
        default: ServicesPage, 
    }},

    {path: "/references", name: 'ReferencesPage',
    components: {
       default: ReferencesPage, 
   }},

    {path: "/contact", name: 'ContactPage',
     components: {
        default: ContactPage, 
    }},
] */