<template>
  <div class="global-container">
    <div class="main-container">
      <ac-header />
      <router-view></router-view>
      <ac-footer />
    </div>
  </div>

</template>

<script>
import acHeader from './components/acHeader.vue';
import acFooter from './components/acFooter.vue';

export default {
  name: 'App',
  components: {
    acHeader,
    acFooter,
}
}
</script>

<style>
</style>
