<template>
    <section id="homepage-header" class="component-container">
        <logo-titled class="hp-header"></logo-titled>
        <h2 class="slogan lg:mb-4">Luxury | Lifestyle</h2>
        <h2 class="slogan mb-32">Communication & Experience Design</h2>
    </section>
</template>

<script>
import logoTitled from "../components/svg/logoTitled.vue"

export default{
    name: "homePageHeader",
    data(){
        return {

        }
    },
    components: {
        logoTitled
    }
}
</script>

<style scoped>

#homepage-header{
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.hp-header{
    width: 45rem;
    justify-self: center;
    grid-column: 1 / -1;
    margin-top: 8.9rem;
    max-height: 25.8rem;
}

h2.slogan{
    font-size: 4.5rem;
    white-space: nowrap;
    justify-self: center;
    grid-column: 4 / 10;
    letter-spacing: -0.02em;
    font-weight: 300;
    color: rgb(63, 63, 63);
    line-height: .3;
}

@media screen and (max-width: 820px){

#homepage-header{
    min-height: 65vh;
}
.hp-header{
    max-width: 40rem;
    height: unset;
}

h2.slogan{
    font-size: 4rem;
}
}

@media screen and (max-width: 600px){

    #homepage-header{
        min-height: 65vh;
    }
    .hp-header{
        max-width: 25rem;
        height: unset;
    }

    h2.slogan{
        font-size: 2rem;
    }
}

@media screen and (max-width: 400px){
    .hp-header{
        max-width: 30rem;
    }

    h2.slogan{
        font-size: 2rem;
    }
}

</style>