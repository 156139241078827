<template>
        <homePageHeader></homePageHeader>
        <div class="transition transition-1"></div>
        <homePageText />
<!--         <div class="transition transition-2"></div>
 -->        <!--<homePageRedirectRow/> -->
        <AboutMePage id="about-me"/>
        <div class="transition transition-2"></div>
        <ourApproach id="services"/>
        <ServicesPage />
        <ReferencesPage id="references"/>
        <div class="transition transition-4"></div>

        <ContactPage id="contact"/>



</template>

<script>
//import homePageRedirectRow from '../components/homePageRedirectRow.vue'
import homePageHeader from "../components/homePageHeader.vue"
import homePageText from "../components/homePageText.vue"

import AboutMePage from "./AboutMePage.vue"
import ourApproach from "../components/ourApproach.vue"
import ReferencesPage from "./ReferencesPage.vue"
import ServicesPage from "./ServicesPage.vue"
import ContactPage from "./ContactPage.vue"

export default{
    name: "HomePage",
    data(){
        return {

        }
    },
    components: {
        // homePageRedirectRow,
        homePageHeader,
        homePageText,
        AboutMePage,
        ourApproach,
        ReferencesPage,
        ServicesPage,
        ContactPage,
    }
}
</script>


<style scoped>


.transition{
    width: 100vw;
    overflow:hidden;
    background-position: top center;
    background-size: cover;
    background-color: var(--color-background);

}

.transition-1{
    background-image: url("../assets/hp-transition-1.jpg");
    background-blend-mode: overlay;
    margin-bottom: 9rem;
    height: 22.8rem;

}

.transition-2{
    background-image: url("../assets/hp-transition-2.jpg");
    background-blend-mode: overlay;
    background-color: var(--color-background);
    height: 11.4rem;
    margin-bottom: 9rem;

}

.transition-3{
    background-image: url("../assets/hp-transition-3.jpg");
    background-blend-mode: overlay;
    background-position: top 45% center;
    background-color: var(--color-background);
    height: 17rem;
    margin-bottom:9rem;

}
.transition-4{
    background-image: url("../assets/hp-transition-4.jpg");
    background-blend-mode: overlay;
    background-color: var(--color-background);
    background-position: top 25% center;
    height: 20rem;

}

@media screen and (max-width: 850px){
    .transition-2{
        display:none;
    }

}

</style>