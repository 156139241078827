<template>
    <header class="aar-header">
        <div class="aar-header__content centered-flex">
            <router-link to="/">
                <logoFull class="aar-header__logo" ></logoFull>
            </router-link>
            <nav class="aar-header__navigation">
                <a href="#about-me" class="aar-header__navigation-link">About Me</a>
                <a href="#services" class="aar-header__navigation-link">Services</a>
                <a href="#references" class="aar-header__navigation-link">References</a>
                <a href="#contact" class="aar-header__navigation-link">Contact</a>

            </nav>
            <resp-menu @menuOpen="toggleScrollState" @menuClosed="unblockScroll" class="hidden-desktop"></resp-menu>
        </div>
    </header>
</template>

<script>
import RespMenu from "./respMenu";
import logoFull from "./svg/logoFull.vue"

export default {
    name: "acHeader",
    data(){
      return {
          isScrollBlocked: false
      }
  },
  methods: {
      toggleScrollState() {
          this.isScrollBlocked = !this.isScrollBlocked
          this.checkScrollClass()
      },
      unblockScroll(){
          this.isScrollBlocked = false
          this.checkScrollClass()
      },
      checkScrollClass(){
          const body = document.getElementsByTagName('body')[0]
          if(this.isScrollBlocked) {
              body.classList.add("blocked-scroll")
          } else {
              body.classList.remove("blocked-scroll")
          }
      }
  },
  components: {
      RespMenu: RespMenu,
      LogoFull: logoFull
  }
}
</script>

<style scoped>

.aar-header{
      height: var(--header-height);
      display: grid;
      grid-column: 1 / -1;
      grid-template-columns: inherit;
      align-items: center;
      max-width: 100vw;
}

.aar-header__content{
    font-family: var(--main-font);
    grid-column: 1 / -1;
    width: var(--site-width);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.aar-header__navigation{
    display: flex;
}

.aar-header__navigation-link{
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--color-green);
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    position: relative;
    white-space: nowrap;
}

.aar-header__logo{
    width: 12.5rem;
    height: 8rem;
    fill: var(--color-green);
}

.aar-header__navigation-link:not(:last-child){
    margin-right: 3.8rem;

}

.hidden-desktop{
    display: none;
}


@media screen and (max-width: 900px){
      .aar-header__navigation {
          display: none;
      }

      .hidden-desktop{
        display: block;
      }
    }

</style>