<template>
    <svg width="625" height="346" viewBox="0 0 625 346" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1147_1700)">
            <path d="M0 310.79C0 296.946 8.2688 286.989 20.0482 286.989C29.5936 286.989 36.905 293.927 36.905 299.484C36.905 301.7 35.6284 303.178 33.4524 303.178C27.9108 303.178 28.201 290.426 18.4235 290.426C12.4467 290.426 7.19531 296.657 7.19531 306.133C7.19531 319.495 14.6807 328.585 22.3403 328.585C28.9843 328.585 33.0752 323.028 34.903 318.82C35.7444 317.407 37.7464 317.76 37.5143 319.752C36.4988 328.103 30.7541 335.715 20.4834 335.715C9.60342 335.715 0 323.542 0 310.79Z" fill="#020911"/>
            <path d="M89.622 333.916V334.109C89.622 334.719 89.1287 335.04 88.3454 335.04H77.6104C76.8271 335.04 76.3338 334.719 76.3338 334.109V333.916C76.3338 332.438 79.4673 332.117 78.5679 329.516L76.2178 322.899H57.9684L55.7344 329.516C54.835 332.117 57.9684 332.471 57.9684 333.916V334.109C57.9684 334.719 57.4752 335.04 56.6338 335.04H49.8157C49.0323 335.04 48.5391 334.719 48.5391 334.109V333.916C48.5391 332.438 50.599 332.374 51.6145 329.516L64.2933 293.477C65.0767 291.26 62.2914 290.008 62.2914 288.723V288.53C62.2914 287.984 62.6686 287.599 63.3069 287.599H68.065C69.6898 287.599 70.6762 288.337 71.3145 290.136L86.4595 329.516C87.533 332.31 89.5349 332.471 89.5349 333.916H89.622ZM74.9412 319.269L66.8465 296.4L59.187 319.269H74.9412Z" fill="#020911"/>
            <path d="M141.789 288.594V288.787C141.789 290.2 139.265 290.522 139.265 293.123V332.76C139.265 334.237 138.54 335.04 137.321 335.04H135.145C134.362 335.04 133.753 334.494 132.911 333.177L108.453 296.4V329.355C108.453 332.021 111.122 332.438 111.122 333.884V334.077C111.122 334.687 110.571 335.008 109.788 335.008H103.579C102.737 335.008 102.244 334.687 102.244 334.077V333.884C102.244 332.406 104.594 332.021 104.594 329.355V293.188C104.594 290.586 102.244 290.297 102.244 288.851V288.594C102.244 287.984 102.737 287.599 103.579 287.599H109.555C110.716 287.599 111.441 288.145 112.08 289.205L135.377 325.115V293.155C135.377 290.554 132.824 290.2 132.824 288.819V288.626C132.824 288.016 133.375 287.631 134.159 287.631H140.426C141.209 287.631 141.76 288.048 141.76 288.626L141.789 288.594Z" fill="#020911"/>
            <path d="M156.092 323.83C156.092 321.967 157.107 320.426 159.051 320.49C164.535 320.683 162.562 332.535 173.848 332.535C178.751 332.535 182.117 329.323 182.117 324.505C182.117 309.119 156.991 320.426 156.991 300.383C156.991 292.898 162.62 287.021 172.571 287.021C182.523 287.021 188.819 293.155 188.761 298.263C188.761 300.318 187.745 301.667 185.627 301.667C179.941 301.667 182.552 290.233 172.397 290.233C167.088 290.233 164.361 293.894 164.361 298.198C164.361 312.652 189.486 302.599 189.486 322.321C189.486 330.8 182.059 335.747 173.558 335.747C161.662 335.747 156.092 328.584 156.092 323.862V323.83Z" fill="#020911"/>
            <path d="M204.225 333.916C204.225 332.503 206.459 332.182 206.459 329.58V293.156C206.459 290.554 204.225 290.2 204.225 288.755V288.562C204.225 287.952 204.718 287.631 205.501 287.631H214.931C215.714 287.631 216.207 287.952 216.207 288.562V288.755C216.207 290.233 213.799 290.554 213.799 293.156V329.58C213.799 332.182 216.207 332.535 216.207 333.916V334.109C216.207 334.719 215.714 335.04 214.931 335.04H205.501C204.718 335.04 204.225 334.719 204.225 334.109V333.916Z" fill="#020911"/>
            <path d="M271.739 288.594V288.787C271.739 290.2 269.214 290.522 269.214 293.123V332.76C269.214 334.237 268.489 335.04 267.271 335.04H265.095C264.311 335.04 263.702 334.494 262.86 333.177L238.402 296.4V329.355C238.402 332.021 241.071 332.438 241.071 333.884V334.077C241.071 334.687 240.52 335.008 239.737 335.008H233.528C232.687 335.008 232.193 334.687 232.193 334.077V333.884C232.193 332.406 234.543 332.021 234.543 329.355V293.188C234.543 290.586 232.193 290.297 232.193 288.851V288.594C232.193 287.984 232.687 287.599 233.528 287.599H239.505C240.665 287.599 241.391 288.145 242.029 289.205L265.327 325.115V293.155C265.327 290.554 262.773 290.2 262.773 288.819V288.626C262.773 288.016 263.325 287.631 264.108 287.631H270.375C271.158 287.631 271.71 288.048 271.71 288.626L271.739 288.594Z" fill="#020911"/>
            <path d="M310.037 334.109V333.916C310.037 332.439 312.329 332.117 312.329 329.516V293.156C312.329 290.554 310.037 290.2 310.037 288.755V288.562C310.037 287.952 310.588 287.631 311.372 287.631H324.66C339.573 287.631 350.076 297.781 350.076 311.175C350.076 325.95 340.472 335.105 327.358 335.105H311.372C310.588 335.105 310.037 334.783 310.037 334.173V334.109ZM326.952 331.636C335.946 331.636 342.59 325.019 342.59 314.13C342.59 300.832 334.205 291.003 323.267 291.003H321.584C320.453 291.003 319.524 291.678 319.524 293.477V326.914C319.524 329.933 320.917 331.668 323.151 331.668H326.952V331.636Z" fill="#020911"/>
            <path d="M364.727 334.109V333.916C364.727 332.439 367.019 332.182 367.019 329.451V293.22C367.019 290.49 364.727 290.136 364.727 288.755V288.562C364.727 287.952 365.22 287.631 365.945 287.631H392.202C393.305 287.631 393.711 288.177 393.885 289.301L395.336 296.528C395.51 297.524 395.104 298.07 394.378 298.07H394.088C391.68 298.07 389.968 290.971 386.487 290.971H376.332C375.113 290.971 374.272 291.71 374.272 293.573V309.151H383.556C386.167 309.151 386.4 304.751 387.792 304.751H388.169C388.721 304.751 389.069 305.233 389.069 306.036V315.543C389.069 316.282 388.692 316.828 388.169 316.828H387.792C386.4 316.828 386.167 312.62 383.556 312.62H374.272L374.214 328.263C374.214 329.997 374.997 331.796 376.941 331.796H388.343C391.912 331.796 393.131 324.441 395.713 324.441H396.148C396.815 324.441 397.164 325.179 397.047 325.982L395.655 333.402C395.423 334.526 394.987 335.137 393.914 335.137H366.09C365.307 335.137 364.756 334.815 364.756 334.205L364.727 334.109Z" fill="#020911"/>
            <path d="M450.547 288.594V288.787C450.547 290.2 448.023 290.522 448.023 293.123V332.76C448.023 334.237 447.298 335.04 446.079 335.04H443.903C443.12 335.04 442.51 334.494 441.669 333.177L417.211 296.4V329.355C417.211 332.021 419.88 332.438 419.88 333.884V334.077C419.88 334.687 419.329 335.008 418.545 335.008H412.337C411.495 335.008 411.002 334.687 411.002 334.077V333.884C411.002 332.406 413.352 332.021 413.352 329.355V293.188C413.352 290.586 411.002 290.297 411.002 288.851V288.594C411.002 287.984 411.495 287.599 412.337 287.599H418.313C419.474 287.599 420.199 288.145 420.837 289.205L444.135 325.115V293.155C444.135 290.554 441.582 290.2 441.582 288.819V288.626C441.582 288.016 442.133 287.631 442.917 287.631H449.184C449.967 287.631 450.518 288.048 450.518 288.626L450.547 288.594Z" fill="#020911"/>
            <path d="M466.244 333.916C466.244 332.503 468.478 332.182 468.478 329.58V293.156C468.478 290.554 466.244 290.201 466.244 288.755V288.563C466.244 287.952 466.737 287.631 467.521 287.631H476.95C477.733 287.631 478.227 287.952 478.227 288.563V288.755C478.227 290.233 475.819 290.554 475.819 293.156V329.58C475.819 332.182 478.227 332.535 478.227 333.916V334.109C478.227 334.719 477.733 335.041 476.95 335.041H467.521C466.737 335.041 466.244 334.719 466.244 334.109V333.916ZM467.695 278.959C467.695 276.228 469.552 274.141 472.453 274.141C475.354 274.141 477.095 276.196 477.095 278.959C477.095 281.721 475.238 283.712 472.453 283.712C469.668 283.712 467.695 281.721 467.695 278.959Z" fill="#020911"/>
            <path d="M527.49 325.436L525.749 333.595C525.517 334.398 525.024 335.072 524.299 335.072H493.632C492.906 335.072 492.297 334.462 492.297 333.723V333.177C492.297 332.438 492.848 331.7 493.254 331.025L517.422 290.907H500.827C497.867 290.907 496.649 298.262 494.067 298.262H493.777C492.877 298.262 492.384 297.652 492.616 296.399L494.241 289.237C494.473 288.177 494.85 287.566 495.982 287.566H524.734C525.401 287.566 525.952 288.177 525.952 288.915V289.59C525.952 290.072 525.459 291.068 524.85 292.063L500.885 331.7H518.583C522.442 331.7 523.892 323.959 526.243 323.959H526.417C527.316 323.959 527.751 324.505 527.49 325.436Z" fill="#020911"/>
            <path d="M540.953 334.109V333.916C540.953 332.439 543.245 332.182 543.245 329.451V293.22C543.245 290.49 540.953 290.136 540.953 288.755V288.562C540.953 287.952 541.446 287.631 542.172 287.631H568.429C569.502 287.631 569.937 288.177 570.112 289.301L571.562 296.528C571.736 297.524 571.33 298.07 570.605 298.07H570.315C567.907 298.07 566.195 290.971 562.713 290.971H552.558C551.34 290.971 550.499 291.71 550.499 293.573V309.151H559.783C562.394 309.151 562.626 304.751 564.019 304.751H564.396C564.947 304.751 565.295 305.233 565.295 306.036V315.543C565.295 316.282 564.918 316.828 564.396 316.828H564.019C562.626 316.828 562.394 312.62 559.783 312.62H550.499L550.44 328.263C550.44 329.997 551.224 331.796 553.168 331.796H564.57C568.139 331.796 569.328 324.441 571.939 324.441H572.375C573.042 324.441 573.39 325.179 573.274 325.982L571.881 333.402C571.649 334.526 571.214 335.137 570.141 335.137H542.317C541.533 335.137 540.982 334.815 540.982 334.205L540.953 334.109Z" fill="#020911"/>
            <path d="M624.107 333.916V334.109C624.107 334.719 623.614 335.04 622.83 335.04H612.328C611.544 335.04 611.051 334.719 611.051 334.109V333.916C611.051 332.439 613.778 332.246 612.328 329.516L604.668 316.828H604.117L596.922 316.957V329.516C596.922 332.117 599.388 332.471 599.388 333.916V334.109C599.388 334.719 598.836 335.04 598.053 335.04H588.566C587.782 335.04 587.289 334.719 587.289 334.109V333.916C587.289 332.439 589.523 332.117 589.523 329.516V293.156C589.523 290.554 587.289 290.2 587.289 288.755V288.562C587.289 287.952 587.782 287.631 588.566 287.631H602.202C612.763 287.631 620.364 292.385 620.451 302.47C620.451 308.027 617.376 313.166 611.457 315.511L620.045 329.548C621.786 332.439 624.078 332.503 624.078 333.948L624.107 333.916ZM612.995 303.434C612.995 295.147 607.511 291.003 601.767 291.003H598.749C597.531 291.003 596.951 292.063 596.951 293.669V309.665C596.951 312.46 597.966 313.488 599.91 313.488H603.304C608.991 313.488 613.024 310.019 613.024 303.466L612.995 303.434Z" fill="#020911"/>
            <path d="M380.481 217.159H251.836V0.0585938H380.481V3.43122H254.882V213.787H380.481V217.159Z" fill="#020911"/>
            <path d="M352.339 166.153H278.297V51.6445H352.339V55.0172H281.343V162.781H352.339V166.153Z" fill="#020911"/>
            <path d="M353.875 138.722H350.829V80.7769H307.773V138.722H304.727V77.4043H353.875V138.722Z" fill="#020911"/>
            <path d="M380.017 191.624H279.805V188.251H376.97V29.1597H279.805V25.7871H380.017V191.624Z" fill="#020911"/>
        </g>
        <defs>
        <clipPath id="clip0_1147_1700">
        <rect width="625" height="346" fill="white"/>
        </clipPath>
        </defs>
</svg>
</template>

<script>

export default {
    name: "LogoTitled",
    
}

</script>